<template>
  <a-modal
    v-model:visible="showTrainingNoteModal"
    title="Label Model Version"
    destroyOnClose
    id="req-annotation-note-modal"
    :okButtonProps="{ id: 'req-note-modal-ok-btn' }"
    :cancelButtonProps="{
      id: 'req-note-modal-cancel-btn',
      style: { marginLeft: 'auto' }
    }"
  >
    <template #closeIcon>
      <close-outlined id="req-train-note-close-btn" />
    </template>
    <a-textarea
      v-model:value="additionalNote"
      placeholder="Add something about Train Model (Optional)"
      :rows="4"
      allowClear
    />
    <template #footer>
      <div class="d-flex w-100 justify-content-end">
        <a-button
          type="primary"
          @click="startTraining"
          :disabled="!additionalNote"
          :loading="isSetToTraining"
          id="save-annotation-progress-btn"
        >
          Save
        </a-button>
      </div>
    </template>
  </a-modal>

  <a-row :gutter="[16, 29]" class="py-4 px-2 p-md-4">
    <a-col :xs="20" :sm="8" :md="8" :lg="8" :xl="8">
      <a-select
        class="w-100"
        :value="selectedTask"
        show-search
        ref="train_task_select"
        placeholder="Select Operation"
        :options="options"
        :filter-option="filterOption"
        :loading="isFetchingTasks"
        @change="handleTaskSelect"
        :disabled="isTraining"
        id="train-model-select-task"
      ></a-select>
    </a-col>
    <a-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8" class="d-none d-sm-block">
      <a-button
        type="primary"
        class="lg-button"
        @click="startTraining"
        :disabled="isTraining || !infoMessage || !selectedTask"
      >
        Request Training
      </a-button>
    </a-col>

    <!-- mobile screen -->
    <a-col :xs="3" class="d-block d-sm-none">
      <div class="demo-dropdown-wrap">
        <a-dropdown-button
          :trigger="['click']"
          placement="bottomRight"
          class="float-right"
        >
          <template #overlay>
            <a-menu :selectedKeys="currentItem">
              <a-menu-item key="training">
                <a-button
                  type="primary"
                  class="w-100"
                  style="width: 100%"
                  :disabled="isTraining || !infoMessage || !selectedTask"
                  @click="startTraining"
                >
                  Request Training
                </a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </a-col>

    <a-col
      span="24"
      v-if="isTraining"
      class="d-flex flex-column align-items-center"
    >
      <h6 class="text-center" v-if="taskName">
        <a-typography-text strong>
          {{ taskName || '' }}
        </a-typography-text>
        &nbsp; is being trained.
      </h6>

      <setting-outlined
        spin
        :style="{ color: '#1890ff', fontSize: '48px' }"
        class="my-3"
      />
    </a-col>

    <a-col
      span="24"
      v-else
      style="height: 20vh"
      class="d-flex align-items-center justify-content-center"
    >
      <a-spin v-if="!infoMessage"></a-spin>
      <h5 class="text-secondary text-center" v-else>{{ infoMessage }}</h5>
    </a-col>

    <a-col span="24">
      <a-tabs v-model:activeKey="tabActiveKeys">
        <a-tab-pane key="trained" id="tasks-trained-tab">
          <template #tab>
            <a-typography-text strong
              >Operations Trained: &nbsp;
              <a-tag color="blue" id="trained-task-count">{{
                modelsList.length
              }}</a-tag></a-typography-text
            ></template
          >

          <a-table
            :columns="columns"
            :data-source="modelsList"
            :pagination="pagination"
            bordered
            :loading="loadingModelList"
            :scroll="{ y: 300, x: true }"
            @change="handleTableChange"
          >
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'startTrainingTime'">
                <span>
                  {{ new Date(text).toDateString() }}
                </span>
              </template>
              <span v-if="column.dataIndex === 'status_update_time'">
                <small>
                  {{ formatDate(text) }}
                </small>
              </span>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="inAnnotation" id="task-under-training-tab">
          <template #tab>
            <a-typography-text strong
              >Operations Under Training: &nbsp;
              <a-tag color="blue" id="tasks-under-training-count">{{
                tasksBeingLabeled.length
              }}</a-tag></a-typography-text
            ></template
          >
          <a-table
            :columns="columns"
            :data-source="tasksBeingLabeled"
            :loading="isLoadingTasksBeingLabeled"
            bordered
            :pagination="{ position: ['bottomCenter'] }"
            :scroll="{ y: 300, x: true }"
          >
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'startTrainingTime'">
                <span>
                  {{ new Date(text).toDateString() }}
                </span>
              </template>
              <span v-if="column.dataIndex === 'status_update_time'">
                <small>
                  {{ formatDate(text) }}
                </small>
              </span>
            </template>
          </a-table></a-tab-pane
        >
      </a-tabs>
    </a-col>
  </a-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import messages from './static/data';
import spaceMixin from 'src/mixins/handleSpace';
import { SettingOutlined, CloseOutlined } from '@ant-design/icons-vue';
import trainingStatuses from 'src/config/training-status-config.js';
import dateHelper from '../../../shared/Helpers/dateHelper';
import { getSortedTask } from 'src/utils/task';
import NotificationService from 'src/services/notification';
import TaskService from '@/services/tasks';
import SQSServices from '@/services/sqs';
import httpClient from 'src/service/httpClient';

export default {
  components: {
    SettingOutlined,
    CloseOutlined
  },
  mixins: [spaceMixin],
  inject: ['toast'],
  data: function() {
    return {
      infoMessage: null,
      loadingModelList: false,
      tasks: [],
      isLoadingTasks: false,
      reqAnnotation: true,
      modelsList: [],
      interval: null,
      isTraining: false,
      trainingProgress: 0,
      stoppingDuraion: 60,
      task_id: null,
      currentPage: 1,
      visiblePopConfirm: false,
      tabActiveKeys: 'trained',
      tasksBeingLabeled: [],
      isLoadingTasksBeingLabeled: false,
      currentItem: ['training'],
      showTrainingNoteModal: false,
      additionalNote: '',
      isSetToTraining: false,
      columns: [
        {
          title: 'Model name',
          dataIndex: 'taskName'
        },
        {
          title: 'Start time',
          dataIndex: 'status_update_time'
        }
      ],
      columnsForTaskBeingTrained: [
        {
          title: 'Task name',
          dataIndex: 'taskName'
        },
        {
          title: 'Start time',
          dataIndex: 'status_update_time'
        }
      ]
    };
  },

  computed: {
    ...mapGetters([
      'taskName',
      'taskDetails',
      'organization',
      'allTasks',
      'selectedTask',
      'isFetchingTasks',
      'taskJson',
      'modelChoice'
    ]),

    options() {
      return getSortedTask(this.allTasks);
    },

    getModelsList() {
      return this.modelsList;
    },

    modelsListLength() {
      return this.modelsList.length;
    },
    pagination() {
      return {
        total: this.modelsList.length,
        current: this.currentPage,
        showSizeChanger: true,
        position: ['bottomCenter']
      };
    }
  },

  watch: {
    selectedTask(value) {
      if (!value) return;
      this.fetchTaskDetails(value);
    },

    taskDetails(value) {
      value && this.setTaskNameAndProcess(value);
    },
    taskJson(value) {
      console.log('task json:', value);
    }
  },

  created() {
    this.getAllTasks();
    this.getTrainedModels();
    this.isModelTraining();
    this.getTasksBeingAnnotated();
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  methods: {
    ...mapActions([
      'getAllTasks',
      'setSelectedTask',
      'fetchTaskDetails',
      'setTaskNameAndProcess'
    ]),

    handleTaskSelect(val) {
      this.setSelectedTask(val);
      this.$refs.train_task_select.blur();
    },

    handleTableChange(pag) {
      const { current } = pag;
      this.currentPage = current;
    },

    async getTrainedModels() {
      this.loadingModelList = true;
      const [error, data] = await TaskService.fetchTrainedTasksByStatus(
        trainingStatuses.trained
      );
      if (error) return;
      this.modelsList = data?.map(({ task, ...model }) => ({
        ...model,
        taskId: task.id,
        taskName: task.taskName
      }));
      this.loadingModelList = false;
    },

    async getTrainingStatus(taskId) {
      const [error, data] = await TaskService.fetchTaskStatus(taskId);
      if (error) return;
      if (data.train_status === trainingStatuses.trained) {
        clearInterval(this.interval);
        this.isTraining = false;
        this.infoMessage = messages.noModelMessage;
        this.getTrainedModels();
      }
    },

    listenToTrainingProgress(taskId) {
      this.getTrainingStatus(taskId);
      this.interval = setInterval(this.getTrainingStatus, 10 * 1000, taskId);
    },

    async isModelTraining() {
      const [error, data] = await TaskService.fetchTrainedTasksByStatus(
        trainingStatuses.training
      );
      if (error) return;
      if (data.length === 0) {
        this.isTraining = false;
        this.infoMessage = messages.noModelMessage;
      } else {
        this.isTraining = true;
        const { id: taskId } = data[0]['task'];
        this.setSelectedTask(taskId);
        this.listenToTrainingProgress(taskId);
      }
    },

    async getTasksBeingAnnotated() {
      this.isLoadingTasksBeingLabeled = true;
      const [error, data] = await TaskService.fetchTrainedTasksByStatus(
        trainingStatuses.in_annotation
      );
      this.isLoadingTasksBeingLabeled = false;
      if (error) return;
      this.tasksBeingLabeled = data?.map(({ task, ...model }) => ({
        ...model,
        taskId: task.id,
        taskName: task.taskName
      }));
    },

    async sendReqAnnotationMail(taskName, taskId) {
      const data_dict = {
        recipient_email: ['support@retrocausal.ai'],
        sender_email: 'postman@retrocausal.ai',
        subject: `Annotation Requested for Task: ${taskName}`,
        body: `Annotation Requested\n\nOrganization: ${this.organization}\nTask Id: ${taskId}\nTask Name: ${taskName}`,
        topic_name: 'General'
      };

      const [error] = await NotificationService.sendNotificationEmail(
        data_dict
      );
      if (error) {
        console.log(error);
      }
    },

    handleVisibleChange() {
      const flag = this.modelsList.map(e => e.id).includes(this.selectedTask);
      if (flag) {
        this.visiblePopConfirm = true;
      } else {
        this.startTraining();
      }
    },
    async send_sqs_msg_for_model_AD() {
      const payload = {
        queueName: 'object-detection',
        sqsMessage: {
          task_id: this.taskJson.taskID,
          name: this.taskJson.name,
          organization: this.organization,
          model_choice: this.modelChoice
        }
      };

      const [error] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log('error:', error);
      }
    },

    async handleCheckTrainingStart() {
      const { selectedTask: taskId } = this;
      const [error, data] = await TaskService.fetchTaskStatus(taskId, false);
      if (error) return;
      const { train_status } = data;
      if (
        train_status === trainingStatuses.in_annotation ||
        train_status === trainingStatuses.training
      ) {
        this.toast.info('This task is already in under training!');
        return;
      }
      this.showTrainingNoteModal = true;
    },

    async loadImagesForAnnotation() {
      const res = await httpClient.getData(
        `organization/task/annotation_image_list/${this.selectedTask}/`,
        false
      );
      if (res === 'error') {
        this.toast.error('error in getting images');
        return null;
      }
      return res.results;
    },

    async countAnnotatedFrames() {
      const frames = await this.loadImagesForAnnotation();
      let count = 0;

      frames.forEach(img => {
        if (img.path_to_annotations) {
          count++;
        }
      });

      return count;
    },

    async startTraining() {
      if (!this.selectedTask) {
        this.toast.info('Please select a task first!');
        return;
      }
      const model_choice = this.taskJson.training_params.model_choice;

      if (model_choice === 'Model AD' || model_choice === 'Model ADF') {
        const annotated_frames = await this.countAnnotatedFrames();

        if (annotated_frames < 5) {
          this.toast.error('Please label atleast 5 frames');
          return;
        }

        this.send_sqs_msg_for_model_AD();
      }

      this.visiblePopConfirm = false;
      if (!this.additionalNote) this.isSetToTraining = true;
      const { selectedTask: taskId, taskName } = this;

      const payload = {
        train_status: trainingStatuses.in_annotation,
        model_version_description: this.additionalNote
      };
      const [error] = await TaskService.updateTrainedTask(taskId, payload);
      this.isSetToTraining = false;
      this.showTrainingNoteModal = false;
      this.additionalNote = '';
      if (error) {
        const errorMessage =
          error?.response?.data?.detail ||
          'Something went wrong, please try again later.';
        this.toast.error(errorMessage);

        return;
      }

      this.sendReqAnnotationMail(taskName, taskId);
      this.toast.success('Task has been sent for training');
      this.sendReqAnnotationMail(taskName, taskId, this.organization);
      this.getTasksBeingAnnotated();
    },

    getTrainingPayload() {
      const formData = new FormData();
      const { id: taskId, taskName } = this.tasks.find(
        el => el.id === this.selectedTask
      );
      formData.append('task_name', taskName);
      formData.append('task_id', taskId);
      formData.append('annotation', this.reqAnnotation ? 1 : 0);
      formData.append('organization', this.organization);
      return formData;
    },

    async stopTraining() {
      const { taskName } = this.tasks.find(t => t.id === this.selectedTask);
      const payload = {
        organization: this.organization,
        task_name: taskName || this.taskName
      };

      const [error] = await TaskService.stopTrainingOfTask(payload, false);
      if (error) {
        console.log('error in stop training!');
        return;
      }
      this.startProcessing();
    },

    startProcessing() {
      this.toast.success(messages.waitMessage);
      this.infoMessage = '';
      this.isTraining = false;
      setTimeout(() => {
        this.infoMessage = messages.noModelMessage;
        this.toast.success(messages.stoppingMessage);
        this.trainingProgress = 0;
      }, this.stoppingDuraion * 1000);
      clearInterval(this.interval);
    },

    formatDate(date) {
      return dateHelper.formatDate(date);
    }
  }
};
</script>
<style scoped>
.form-check {
  padding-top: 1.5em;
  display: flex;
  align-items: center;
}
.lg-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
